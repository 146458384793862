/* eslint-disable max-len */
//Guia de personalizacao de Aplicativo 2.0

export const environment = {
  urlImageHapolo: 'starnovoprincipal.png',
  apiKey: '',
  googleApiKey: '',
  googleFlag: 0,
  color: '#00214d',
  ip: '138.197.74.245',
  apelido: 's03',
  admin: '7813',
  allowAccountCreation: false,
  pacote: 'br.appstarassociado.com',
  nomeApp: 'Star Associado',
  buildAtual: 20102, //Incrementar em um a cada release
  versaoAtual: '2.1.2', //Incrementar a cada release
  chaveOneSignal: 'd060726b-1980-46e3-b903-1d3a59ef9550',
};
// ** IMAGEM FTP **
// Em arquivo, 1 imagem para a logo por ftp deve ser enviada para a pasta adm
// esta sera usadas no campot urlImageHapolo

// ** SPLASH E ICON **
//npm install @capacitor/assets
//o icon em 1024px precisa ser colocado em uma pasta chamada assets (mesmo caminho do resources)
//npx @capacitor/assets generate

// ** ICONE PERSONALITE PUSH **
//Apos os processos de pasta buildada, caso tenha o iconePersonalizado
//Jogar o SRC do icone de push personalizado (com nome FIXO de ic_stat_logo_personalizado)
// Gerar no site:
//http://romannurik.github.io/AndroidAssetStudio/icons-notification.html#source.type=clipart&source.clipart=ac_unit&source.space.trim=1&source.space.pad=0&name=ic_stat_logo_personalizado


// ** SPLASH PERSONALITE **
// Apenas substituir o gif PROJETO.gif em /src/assets por outro gif de 6 segundos.


// O CODIGO DA VERSAO E VERSAO, São alterados no seguinte caminho:
// android/app/build.gradle
//versionCode 1
//versionName "1.0"
//Trocar pelo conteudo de buildAtual e versaoAtual do enviroment respectivamente
