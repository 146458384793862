import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent {
  @ViewChild('signatureCanvas', { static: false }) signatureCanvas!: ElementRef<HTMLCanvasElement>;
  private signaturePad!: SignaturePad;

  customerBase64Signature: string;
  customerColor = localStorage.getItem('cor');

  constructor(private modalController: ModalController) {}

  ngAfterViewInit() {
    const canvas = this.signatureCanvas.nativeElement;

    this.signaturePad = new SignaturePad(canvas, {
      penColor: '#000000',
    });

    this.canvasResize();

    window.onresize = () => this.canvasResize();

    canvas.addEventListener('touchstart', this.preventScroll, {
      passive: false,
    });
    canvas.addEventListener('touchmove', this.preventScroll, {
      passive: false,
    });
  }

  preventScroll(event: TouchEvent): void {
    event.preventDefault();
  }

  canvasResize() {
    const canvas = this.signatureCanvas.nativeElement;
    const maxWidth = 550
    const maxHeight = 300
    const width = window.innerWidth - 0.1 * window.innerWidth
    const height = window.innerHeight - 0.5 * window.innerHeight;

    if (width >= maxWidth) {
      canvas.width = maxWidth
    } else {
      canvas.width = width
    }

    if (height >= maxHeight) {
      canvas.height = maxHeight
    } else {
      canvas.height = height
    }

  }

  confirmSignature() {
    const sign = this.signaturePad.toDataURL();

    console.log(sign);
    return this.modalController.dismiss(sign, 'confirmed');
  }

  closeModal() {
    return this.modalController.dismiss(null, 'cancel');
  }

  drawClear() {
    this.signaturePad.clear();
    this.customerBase64Signature = '';
  }

  ngOnDestroy() {
    const canvas = this.signatureCanvas.nativeElement;
    window.onresize = null;
    canvas.removeEventListener('touchstart', this.preventScroll);
    canvas.removeEventListener('touchmove', this.preventScroll);
  }
}
